
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'cta-button',
  props: {
    msg: { type: String, required: true },
    secondary: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const ctaType = props.secondary ? 'cta-secondary ' : 'cta-main ';
    return { ctaType };
  },
});
