
import { defineComponent, onMounted } from 'vue';
import { SunIcon, MoonIcon } from '@heroicons/vue/outline';
import { themeStore } from '@stores/theme-store';

export default defineComponent({
  name: 'theme-toggle-component',
  components: { SunIcon, MoonIcon },
  setup() {
    const themeState = themeStore();
    onMounted(() => {
      themeState.setTheme();
    });
    return {
      themeState,
    };
  },
});
