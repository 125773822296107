
import { defineComponent, markRaw, onMounted } from 'vue';
import { docsStore, RepoCategory } from '@stores/docs-store';
import { storeToRefs } from 'pinia';
import repo from '@docs/repo.json';
import Navbar from '@components/navbar-component.vue';
import { themeStore } from '@stores/theme-store';
import { MenuIcon } from '@heroicons/vue/outline';
import headerStore from '@stores/header-store';
import headerComponent from '@components/header-component.vue';

export default defineComponent({
  components: { Navbar, MenuIcon, headerComponent },
  setup() {
    const store = docsStore();
    const storeRef = storeToRefs(store);
    const themeState = themeStore();
    const themeStateRef = storeToRefs(themeState);
    const headerState = headerStore();
    const headerStateRef = storeToRefs(headerState);

    // If we don't already have the files, then import the files without categories if present
    onMounted(async () => {
      if (store.rootFiles.length || !repo.rootFiles.length) return;
      await Promise.all(
        repo.rootFiles.map(async (file) => {
          const importFile = await import(`../docs/docs/${file.filename}.md`);
          store.rootFiles.push({ name: file.prettyName, component: importFile });
        }),
      );

      store.selectedDocKey = store.rootFiles[0].name;
    });

    // If we don't already have the files, then import the categories and their respective files if they exist
    onMounted(async () => {
      if (!store.categoryFiles.length || repo.categories.length) {
        await Promise.all(
          repo.categories.map(async (category: RepoCategory) => {
            const categoryFiles = await Promise.all(
              category.files.map(async (file) => {
                const importFile = await import(`../docs/docs/${file.filename}.md`);
                return {
                  name: file.prettyName,
                  component: importFile,
                };
              }),
            );

            store.categoryFiles.push({
              id: category.id,
              files: categoryFiles,
            });
          }),
        );
      }
    });

    return {
      themeStateRef,
      headerStateRef,
      headerState,
      toggleNav() {
        headerState.mobileActive = !headerState.mobileActive;
      },
      handleSelectGuide(file: any, key: string) {
        store.selectedDocComponent = markRaw(file);
        store.selectedDocKey = key;
        headerState.mobileActive = false;
      },
      rootFiles: storeRef.rootFiles,
      categoryFiles: storeRef.categoryFiles,
      selectedDocComponent: markRaw(storeRef.selectedDocComponent),
      selectedDocKey: markRaw(storeRef.selectedDocKey),
    };
  },
});
