import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "aiq-docs-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_icon = _resolveComponent("menu-icon")!
  const _component_header_component = _resolveComponent("header-component")!
  const _component_navbar = _resolveComponent("navbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_menu_icon, {
      class: "mobile action menu-icon",
      onClick: _ctx.toggleNav
    }, null, 8, ["onClick"]), [
      [_vShow, !_ctx.headerState.mobileActive]
    ]),
    _createVNode(_component_header_component),
    _createVNode(_component_navbar, {
      selectedDocKey: _ctx.selectedDocKey,
      rootFiles: _ctx.rootFiles,
      categoryFiles: _ctx.categoryFiles,
      onHandleSelect: _ctx.handleSelectGuide,
      class: _normalizeClass([_ctx.headerState.mobileActive ? 'mobile-active' : 'mobile-disabled']),
      onCloseNav: _ctx.toggleNav
    }, null, 8, ["selectedDocKey", "rootFiles", "categoryFiles", "onHandleSelect", "class", "onCloseNav"]),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.selectedDocComponent.default)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["docs-container", [_ctx.themeStateRef.theme.value]])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedDocComponent.default), { class: "markdown-body" }))
          ], 2))
        : (_ctx.rootFiles && _ctx.rootFiles.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["docs-container", [_ctx.themeStateRef.theme.value]])
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.rootFiles[0].component.default), { class: "markdown-body" }))
            ], 2))
          : _createCommentVNode("", true)
    ], 1024))
  ]))
}