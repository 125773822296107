import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AIQDocs from '@views/aiq-docs.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'docs',
    component: AIQDocs,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to): any {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});

export default router;
