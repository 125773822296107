import { defineStore } from 'pinia';

export type MdFile = {
  name: string;
  component: any;
};

export type DocCategory = {
  id: string;
  files: MdFile[];
};

export type RepoFile = {
  filename: string;
  prettyName: string;
};

export type RepoCategory = {
  id: string;
  files: RepoFile[];
};

export const docsStore = defineStore({
  id: 'docs',
  state: () => ({
    selectedDocKey: 'Loading...' as string,
    selectedDocComponent: {} as any,
    rootFiles: [] as MdFile[],
    categoryFiles: [] as DocCategory[],
  }),
});
