import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_MoonIcon = _resolveComponent("MoonIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["toggle-dark-mode-container", _ctx.themeState.theme]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.themeState.toggleTheme && _ctx.themeState.toggleTheme(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["sun-icon-container", _ctx.themeState.theme])
      }, [
        _createVNode(_component_SunIcon, { class: "icon" })
      ], 2), [
        [_vShow, _ctx.themeState.theme === 'light']
      ]),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["moon-icon-container", _ctx.themeState.theme])
      }, [
        _createVNode(_component_MoonIcon, { class: "icon" })
      ], 2), [
        [_vShow, _ctx.themeState.theme === 'dark']
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["background", _ctx.themeState.theme])
    }, null, 2)
  ], 2))
}