import { keycloakStore } from '@stores/keycloak-store';
import Keycloak from 'keycloak-js';

export const setupKeycloak = async (): Promise<void> => {
  const keycloakState = keycloakStore();
  const keycloak = new Keycloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
  });

  await keycloak
    .init({ onLoad: 'login-required' })
    .catch(() => {
      throw new Error('Failed to init keycloack, please contact support.');
    });

  setInterval(() => {
    keycloak.updateToken(60).catch(async () => {
      // Failed to refresh the token, or the session has expired clear the token
      await keycloak.login();
    });
  }, 10000);

  keycloakState.keycloak = keycloak;
};
