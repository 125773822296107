
import { defineComponent, type PropType } from 'vue';
import type { MdFile, DocCategory } from '@stores/docs-store';
import LightModeLogo from '@assets/devcode.svg';
import DarkModeLogo from '@assets/devcode_dark.svg';
import { themeStore } from '@stores/theme-store';
import { storeToRefs } from 'pinia';
import CtaButton from '@components/button-component.vue';
import ThemeToggleButton from '@components/theme-toggle-component.vue';
import { XIcon } from '@heroicons/vue/outline';

export default defineComponent({
  name: 'navbar-component',
  props: {
    selectedDocKey: { type: String, required: true },
    rootFiles: { type: Array as PropType<MdFile[]>, required: true },
    categoryFiles: { type: Array as PropType<DocCategory[]>, required: true },
  },
  components: {
    CtaButton,
    ThemeToggleButton,
    XIcon,
  },
  setup(_, ctx) {
    const themeState = themeStore();
    const themeStateRef = storeToRefs(themeState);

    return {
      DarkModeLogo,
      LightModeLogo,
      themeStateRef,
      closeNav() {
        ctx.emit('closeNav');
      },
      handleSelectGuide(component: any, filename: string) {
        ctx.emit('handleSelect', component, filename);
      },
      handleBackofficeClick() {
        window.open(process.env.VUE_APP_BO_REDIRECT_URL, '_blank');
      },
    };
  },
});
