import { defineStore } from 'pinia';

const headerStore = defineStore({
  id: 'docs',
  state: () => ({
    mobileActive: false as boolean,
    logo: '' as string,
  }),
});

export default headerStore;
