import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { setupKeycloak } from '@security/keycloak-setup';
import App from './App.vue';
import router from './routes/index';

const app = createApp(App);

app.use(router);
app.use(createPinia());

const isProd = process.env?.NODE_ENV === 'production';
if (isProd) {
  await setupKeycloak();
}

app.mount('#app');
