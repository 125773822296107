import { defineStore } from 'pinia';

// eslint-disable-next-line no-shadow
export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export const themeStore = defineStore({
  id: 'theme',
  state: () => ({
    theme: Theme.Light as Theme,
  }),
  actions: {
    setTheme() {
      const localTheme = localStorage.getItem('data-theme');
      let newTheme: Theme = Theme.Light;

      if (localTheme && localTheme === Theme.Light) newTheme = Theme.Dark;
      else if (localTheme && localTheme === Theme.Dark) newTheme = Theme.Light;
      else if (window.matchMedia('(prefers-color-scheme: dark)').matches) newTheme = Theme.Dark;

      this.theme = newTheme;
      document.documentElement.setAttribute('data-theme', newTheme);
    },
    toggleTheme() {
      const reverseTheme = this.theme === Theme.Light ? Theme.Dark : Theme.Light;
      this.theme = reverseTheme;
      document.documentElement.setAttribute('data-theme', reverseTheme);
    },
  },
});
